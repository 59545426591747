import React from 'react';
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap';
import { Nav, Navbar, NavDropdown, Form } from 'react-bootstrap';
import { Paths, SSO_LOGOUT_CALLBACK_URL } from 'utils/const';
import LogoutButton from 'shared/components/LogoutButton/LogoutButton';
import { useGetUserQuery } from 'modules/current-user/redux/queries';
import { usePatchUserSettingsMutation } from 'modules/user-settings/redux/queries';

function NavigationBar(): JSX.Element {
  const { data: { user, settings } = {} } = useGetUserQuery(undefined);
  const [patchUserSettings] = usePatchUserSettingsMutation(undefined);
  const showTips = !!settings?.attributes?.showTips;
  const changeShowTips = (): void => {
    if (!settings) {
      return;
    }

    patchUserSettings({
      userId: user?.id,
      settings: {
        ...settings,
        attributes: { ...settings.attributes, showTips: !showTips },
      },
    });
  };

  return (
    <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
      <LinkContainer to={Paths.ROOT}>
        <Navbar.Brand className="align-self-start">/evrone.worklogs</Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="user-nav" />
      <Navbar.Collapse id="user-nav">
        <Nav className="mr-auto flex-wrap flex-grow-1">
          <IndexLinkContainer to={Paths.ROOT}>
            <Nav.Link>Dashboard</Nav.Link>
          </IndexLinkContainer>
          <LinkContainer to={Paths.INSTANCES}>
            <Nav.Link>JIRA servers</Nav.Link>
          </LinkContainer>
          <LinkContainer to={Paths.CORE_PROJECTS}>
            <Nav.Link>Core Projects</Nav.Link>
          </LinkContainer>
          <LinkContainer to={Paths.REPORTS}>
            <Nav.Link>Reports</Nav.Link>
          </LinkContainer>
          <div className="ml-sm-auto" />
          <NavDropdown
            title={user?.attributes?.email || 'user email'}
            id="userEmail"
          >
            <div className="px-3">
              <Form.Switch
                type="switch"
                label="Show tips"
                id="Show-tips"
                checked={showTips}
                onChange={changeShowTips}
              />
            </div>
          </NavDropdown>
          <LogoutButton action={SSO_LOGOUT_CALLBACK_URL} />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
