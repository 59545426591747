import { baseApi } from 'store/api/baseApi';
import { IJiraProjectsRecord } from 'modules/jira-projects/types';

export const projectMatcherApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    matchProjects: builder.mutation<IJiraProjectsRecord[], { coreProjectId: string; jiraProjectIds: string[] }>({
      query: ({ coreProjectId, jiraProjectIds }) => ({
        url: '/project_matcher',
        method: 'PUT',
        body: {
          core_project_id: coreProjectId,
          jira_project_ids: jiraProjectIds,
        },
      }),
      invalidatesTags: ['CoreProjects'],
    }),
  }),
});

export const { useMatchProjectsMutation } = projectMatcherApi;
