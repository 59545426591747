import { useLocation } from 'react-router-dom';

export interface IUseGetParameters {
  startDateParameter: string;
  endDateParameter: string;
}

export const useDateGetParameters = (): IUseGetParameters => {
  const { search } = useLocation();
  const searchParameters = new URLSearchParams(search);

  return {
    startDateParameter: searchParameters.get('filter[start_date]') || '',
    endDateParameter: searchParameters.get('filter[end_date]') || '',
  };
};
