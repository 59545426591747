import React from 'react';
import { Spinner } from 'react-bootstrap';
import styles from 'modules/app/components/App/App.module.scss';

function AppSpinner(): JSX.Element {
  return (
    <div className={styles.spinnerWrapper}>
      <Spinner className={styles.spinner} animation="border" />
    </div>
  );
}

export default AppSpinner;
