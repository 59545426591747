import { useHistory } from 'react-router-dom';
import { usePostInstanceMutation, useUpdateInstanceMutation } from 'modules/instances/redux/queries';
import { useCallback, useState } from 'react';
import { Paths } from 'utils/const';
import { getApiErrors } from 'utils/get-api-errors';
import { IInstanceRecord } from 'modules/instances/types';

interface IFormValues {
  jiraToken: string;
  name: string;
  url: string;
}
type IUseServerInfoFormReturn = [
  ({ jiraToken, name, url }: IFormValues) => void,
  { jiraTokenError: string; nameError: string; urlError: string }
]

export const useServerInfoSubmit = (instance?: IInstanceRecord): IUseServerInfoFormReturn => {
  const history = useHistory();
  const [postInstance] = usePostInstanceMutation();
  const [updateInstance] = useUpdateInstanceMutation();
  const [errors, setErrors] = useState({ jiraTokenError: '', nameError: '', urlError: '' });

  const create = useCallback(({ jiraToken, name, url }) => {
    (instance
      ? updateInstance({ ...instance, attributes: { jiraToken, name, url } })
      : postInstance({ jiraToken, name, url })
    )
      .unwrap()
      .then(() => history.push(Paths.INSTANCES))
      .catch(response => {
        const { jira_token: jiraTokenError = '', name: nameError = '', url: urlError = '' } = getApiErrors(response);

        setErrors({ jiraTokenError, nameError, urlError });
      });
  }, [postInstance, history]);

  return [create, errors];
};
