import React, { useCallback } from 'react';
import { useDeleteInstanceMutation } from 'modules/instances/redux/queries';
import { IInstanceRecord } from 'modules/instances/types';
import { Link } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import { Paths } from 'utils/const';
import styles from 'modules/instances/components/InstanceCard/instance-card.module.scss';

interface IInstanceCardProps {
  instance: IInstanceRecord;
}

export function InstanceCard({ instance }: IInstanceCardProps): JSX.Element | null {
  const { id, attributes: { name, url } } = instance;
  const [deleteInstance] = useDeleteInstanceMutation();
  const onDelete = useCallback(() => deleteInstance(id), [deleteInstance, id]);

  return (
    <Card className={styles.instanceCard}>
      <div className={styles.instanceCard__content}>
        <span className={styles.instanceCard__name}>{name}</span>
        <span className={styles.instanceCard__url}>{url}</span>
        <Link to={Paths.INSTANCE.replace(':id', id)} className={styles.instanceCard__editBtn}>
          <Button variant="primary">Edit</Button>
        </Link>
        <Button onClick={onDelete} variant="dark">Delete</Button>
      </div>
    </Card>
  );
}
