import { useCallback } from 'react';
import showNotification from 'utils/showNotification';
import { useCalculateWorklogsMutation } from 'modules/worklogs/redux/queries';
import { useUpdatedJiraSync } from 'modules/instances/hooks/useUpdatedJiraSync';
import { useDateGetParameters } from './useDateGetParameters';

export const useWorkLogsCalculation = (): { calculateWorkLogs: () => void; inProccess: boolean; } => {
  const [calculateWorklogs, { isLoading }] = useCalculateWorklogsMutation();
  const { startDateParameter, endDateParameter } = useDateGetParameters();
  const { syncUpdatedJira, isSyncing } = useUpdatedJiraSync(startDateParameter, endDateParameter);
  const inProccess = isSyncing || isLoading;

  const calculateWorkLogs = useCallback(() => {
    syncUpdatedJira()
      .then(() => calculateWorklogs({ startDate: startDateParameter, endDate: endDateParameter }).unwrap())
      .then(() => showNotification('success', { content: 'Worklogs calculation succeed' }))
      .catch(() => showNotification('error', { content: 'Worklogs calculation failed' }));
  }, [syncUpdatedJira]);

  return { calculateWorkLogs, inProccess };
};
