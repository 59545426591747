import snakeCase from 'lodash/snakeCase';

type TObject = Record<string, unknown>;

export const objectToSnakeCase = (object: TObject): TObject => (
  Object.entries(object).reduce((accumulator, [key, value]) => {
    let field = value;

    if (typeof field === 'object' && field !== null) {
      field = objectToSnakeCase(field as TObject);
    }
    const newKey = snakeCase(key);

    if (newKey !== key) delete accumulator[key];

    accumulator[newKey] = field;

    return accumulator;
  }, object)
);

export const handleRequestBody = (data: TObject):string => JSON.stringify(
  objectToSnakeCase(data),
);
