import React from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import { useGetInstancesQuery } from 'modules/instances/redux/queries';
import { AddInstanceButton } from 'modules/instances/components/AddInstanceButton/AddInstanceButton';
import { InstanceCard } from 'modules/instances/components/InstanceCard/InstanceCard';
import { useJiraSync } from 'modules/instances/hooks/useJiraSync';
import AppSpinner from 'modules/app/components/AppSpinner/AppSpinner';
import { JiraSyncModal } from 'modules/instances/components/JiraSyncModal/JiraSyncModal';
import { userApi } from 'modules/current-user/redux/queries';

function InstancesPage(): JSX.Element {
  const { data: instances = [] } = useGetInstancesQuery(undefined);
  const { syncJira, isSyncing } = useJiraSync();
  const [modalShow, setModalShow] = React.useState(false);
  const showTips = userApi.endpoints.getUser.useQueryState(undefined, {
    selectFromResult: state => state.data?.settings?.attributes?.showTips,
  });

  const onSyncClick = (): void => {
    if (showTips) {
      setModalShow(true);
    } else {
      syncJira();
    }
  };

  return (
    <Container>
      {isSyncing && <AppSpinner />}
      {showTips && (
        <Alert variant="primary">
          Add all your Jira servers on this page and when all of them will be added press Sync from Jira
        </Alert>
      )}
      <div className="d-flex justify-content-between align-content-center">
        <h1>JIRA servers</h1>
        <Button
          variant="primary"
          disabled={isSyncing}
          onClick={onSyncClick}
          className="mb-2"
        >
          Sync from jira
        </Button>
      </div>
      {instances.map(instance => <InstanceCard key={instance.id} instance={instance} />)}
      <AddInstanceButton />
      {modalShow && <JiraSyncModal hideModal={() => setModalShow(false)} />}
    </Container>
  );
}

export default InstancesPage;
