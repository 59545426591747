import React from 'react';
import Select, { MultiValue } from 'react-select';
import { ISelectOption } from 'typings/shared';

export interface IJiraProjectsSelectProps {
  onSelectChange: (values: MultiValue<{ label: string; value: string; }>) => void;
  options: MultiValue<ISelectOption>;
  defaultOptions: MultiValue<ISelectOption>;
}

export function JiraProjectsSelect(
  {
    onSelectChange,
    defaultOptions,
    options,
  }: IJiraProjectsSelectProps,
): JSX.Element {
  return (
    <Select
      closeMenuOnSelect={false}
      closeMenuOnScroll
      isSearchable
      defaultValue={defaultOptions}
      options={options}
      onChange={onSelectChange}
      isMulti
    />
  );
}
