import { useCallback, useState } from 'react';
import { ISelectHandler } from '@evrone-erp/evrone-erp-ui/lib/components/MonthsNav';

export interface IUseMonthsNavigation {
  yearValue: number;
  monthValue: number;
  handleSelectMonth: ISelectHandler;
  currentDate: Date;
}

export const useMonthsNavigation = (): IUseMonthsNavigation => {
  const currentDate = new Date();
  const [yearValue, selectYear] = useState(currentDate.getFullYear());
  const [monthValue, selectMonth] = useState(currentDate.getMonth());
  const handleSelectMonth = useCallback<ISelectHandler>((
    year,
    month,
  ) => {
    selectYear(year);
    selectMonth(month);
  }, []);

  return { yearValue, monthValue, handleSelectMonth, currentDate };
};
