import React from 'react';
import { toast, TypeOptions } from 'react-toastify';
import Notification, { INotificationProps } from 'shared/components/Notification/Notification';

export default function showNotification(
  type: TypeOptions,
  { title, content }: INotificationProps,
): void {
  toast(<Notification title={title} content={content} />, { type });
}
