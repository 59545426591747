// eslint-disable-next-line max-len
import { useSyncUpdatedJiraWorklogsMutation, useSyncJiraProjectsMutation } from 'modules/jira-projects/redux/queries';
import { useSyncJiraUsersMutation } from 'modules/jira-users/redux/queries';
import showNotification from 'utils/showNotification';

interface IUseUpdatedJiraSyncReturn {
  syncUpdatedJira: () => Promise<void>;
  isSyncing: boolean;
}

// eslint-disable-next-line max-len
export const useUpdatedJiraSync = (startDateParameter?: string, endDateParameter?: string): IUseUpdatedJiraSyncReturn => {
  const [syncJiraProjects, { isLoading: isProjectsSyncing }] = useSyncJiraProjectsMutation();
  const [syncJiraUsers, { isLoading: isUserSyncing }] = useSyncJiraUsersMutation();
  const [syncUpdatedJiraWorklogs, { isLoading: isIssuesSyncing }] = useSyncUpdatedJiraWorklogsMutation();

  const syncUpdatedJira = (): Promise<void> => Promise.all([
    syncJiraProjects(undefined).unwrap(),
    syncJiraUsers(undefined).unwrap(),
  ])
    .then(() => syncUpdatedJiraWorklogs({ startDate: startDateParameter, endDate: endDateParameter }).unwrap())
    .then(() => showNotification('success', { content: 'Jira synchronization succeed' }))
    .catch(() => showNotification('error', { content: 'Jira synchronization failed' }));

  return {
    syncUpdatedJira,
    isSyncing: isUserSyncing || isProjectsSyncing || isIssuesSyncing,
  };
};
