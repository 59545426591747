import React from 'react';
import { Container } from 'react-bootstrap';
import InstanceForm from 'modules/instances/components/InstanceForm/InstanceForm';

function InstancePage(): JSX.Element {
  return (
    <Container>
      <h1>JIRA server</h1>
      <InstanceForm />
    </Container>
  );
}

export default InstancePage;
