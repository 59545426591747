import React, { useCallback, useRef } from 'react';
import { Nav } from 'react-bootstrap';

interface ILogoutButtonProps {
  action: string;
}

function LogoutButton({ action }: ILogoutButtonProps): JSX.Element {
  const formElement = useRef<HTMLFormElement>(null);

  const handleLogoutClick = useCallback(() => {
    if (formElement && formElement.current) {
      formElement.current.submit();
    }
  }, []);

  return (
    <>
      <form
        ref={formElement}
        action={action}
        method="post"
      >
        <input type="hidden" name="_method" value="DELETE" />
      </form>
      <Nav.Link
        role="button"
        onClick={handleLogoutClick}
      >
        Log out
      </Nav.Link>
    </>
  );
}

export default LogoutButton;
