import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { JiraProjectsSelect } from 'modules/jira-projects/components/jira-projects-select/JiraProjectsSelect';
import { useMatchProjectsMutation } from 'modules/project-matcher/redux/queries';
import Select, { MultiValue, SingleValue } from 'react-select';
import { ISelectOption } from 'typings/shared';
import { formatJiraProjectOptions } from 'modules/jira-projects/helpers/format-jira-project-options';
import { IJiraProjectsRecord } from 'modules/jira-projects/types';
import showNotification from 'utils/showNotification';
import { instancesApi } from 'modules/instances/redux/queries';
import { formatInstancesOptions } from 'modules/instances/helpers/format-instances-options';
import { useGetJiraProjectsQuery } from 'modules/jira-projects/redux/queries';

interface ICoreProjectModalProps {
  onCloseClick: () => void;
  coreProjectId: string;
  relatedJiraProjects: IJiraProjectsRecord[];
}

function AttachJiraProjectsModal({
  onCloseClick,
  coreProjectId,
  relatedJiraProjects,
}: ICoreProjectModalProps): JSX.Element | null {
  const [matchProjects] = useMatchProjectsMutation(undefined);
  const { data: instances = [] } = instancesApi.endpoints.getInstances.useQueryState(undefined);
  const instanceOptions = formatInstancesOptions(instances);
  const projectOptions = formatJiraProjectOptions(relatedJiraProjects);

  const [selectedProjects, setSelectedProjects] = useState<MultiValue<ISelectOption>>(projectOptions);
  const [selectedInstance, setSelectedInstance] = useState<SingleValue<ISelectOption>>(() => (
    instanceOptions.find(({ value }) => value === relatedJiraProjects?.[0]?.attributes?.instanceId)
      || instanceOptions[0]
  ));

  const { jiraProjectOptions } = useGetJiraProjectsQuery(selectedInstance?.value, {
    selectFromResult: state => ({ ...state, jiraProjectOptions: formatJiraProjectOptions(state.data || []) }),
    skip: !selectedInstance?.value,
  });

  const onAttach = useCallback(() => {
    matchProjects({ coreProjectId, jiraProjectIds: selectedProjects.map(({ value }) => value) })
      .unwrap()
      .then(() => {
        showNotification('success', { content: 'Jira projects attachment succeed' });
        onCloseClick();
      })
      .catch(() => showNotification('error', { content: 'Jira projects attachment failed' }));
  }, [matchProjects, selectedProjects, onCloseClick, coreProjectId]);

  const onSelectChange = useCallback((values: MultiValue<ISelectOption>) => {
    setSelectedProjects(values);
  }, []);

  return (
    <Modal backdrop="static" onHide={onCloseClick} show>
      <Modal.Header closeButton>
        <Modal.Title>Select Jira projects</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!instanceOptions?.length && (
          <Select
            className="mb-3"
            closeMenuOnScroll
            isSearchable
            defaultValue={selectedInstance}
            options={instanceOptions}
            onChange={setSelectedInstance}
          />
        )}
        {!!selectedInstance && (
          <JiraProjectsSelect
            onSelectChange={onSelectChange}
            defaultOptions={selectedProjects}
            options={jiraProjectOptions}
          />
        )}
        <Button className="mt-3" variant="primary" onClick={onAttach}>Attach</Button>
      </Modal.Body>
    </Modal>
  );
}

export default AttachJiraProjectsModal;
