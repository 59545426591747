import React from 'react';
import { MonthsNav } from '@evrone-erp/evrone-erp-ui/lib/components/MonthsNav';
import { getYears } from 'utils/getYears';
import { IUseMonthsNavigation } from 'shared/hooks/useMonthsNavigation';

export function MonthsNavigation(
  { yearValue, monthValue, handleSelectMonth, currentDate }: IUseMonthsNavigation,
): JSX.Element {
  return (
    <MonthsNav
      years={getYears()}
      yearValue={yearValue}
      monthValue={monthValue}
      currentMonthValue={currentDate.getMonth()}
      currentYearValue={currentDate.getFullYear()}
      onSelect={handleSelectMonth}
    />
  );
}
