import { baseApi } from 'store/api/baseApi';

interface ISendToCoreParameters {
  projectIds: string[];
  startDate?: string;
  endDate?: string;
  userId?: string;
}

export const sendToCoreApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    sendToCore: builder.mutation<undefined, ISendToCoreParameters>({
      query: ({ projectIds, startDate, endDate, userId }) => {
        const body: { [key: string]: string | string[] } = { project_ids: projectIds };

        if (startDate) {
          body['start_date'] = startDate;
        }

        if (endDate) {
          body['end_date'] = endDate;
        }

        return ({
          url: '/send_tracks_to_erp_core',
          method: 'POST',
          body: {
            project_ids: projectIds,
            start_date: startDate,
            end_date: endDate,
            user_id: userId,
          },
        });
      },
    }),
  }),
});

export const { useSendToCoreMutation } = sendToCoreApi;
