import { baseApi } from 'store/api/baseApi';

export const coreUsersApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getCoreUsers: builder.query<undefined, undefined>({
      query: () => '/core/users',
    }),
    syncCoreUsers: builder.mutation<undefined, undefined>({
      query: () => ({
        url: '/core/users/sync',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetCoreUsersQuery,
  useSyncCoreUsersMutation,
} = coreUsersApi;
