import showNotification from 'utils/showNotification';
import { useSendToCoreMutation } from 'modules/send-to-core/redux/queries';
import { useSyncCoreMilestonesMutation } from 'modules/core-milestones/redux/queries';
import { useDateGetParameters } from 'modules/dashboard/hooks/useDateGetParameters';
import { useCalculateWorklogsMutation } from 'modules/worklogs/redux/queries';

interface IUseSendToCore {
  projectIds: string[];
}
type TUseSendToCoreReturn = (userId?: string) => void;

export const useSendToCore = ({ projectIds }: IUseSendToCore): TUseSendToCoreReturn => {
  const [sendTracks] = useSendToCoreMutation(undefined);
  const [syncMilestones] = useSyncCoreMilestonesMutation(undefined);
  const [calculateWorklogs] = useCalculateWorklogsMutation();
  const { startDateParameter, endDateParameter } = useDateGetParameters();

  return userId => {
    sendTracks({ projectIds, startDate: startDateParameter, endDate: endDateParameter, userId })
      .unwrap()
      .then(() => syncMilestones({ startDate: startDateParameter, endDate: endDateParameter }))
      .then(() => calculateWorklogs({ startDate: startDateParameter, endDate: endDateParameter }))
      .then(() => showNotification('success', { content: 'Tracks have been successfully sent to core' }))
      .catch(() => showNotification('error', { content: 'Tracks failed to send to core' }));
  };
};
