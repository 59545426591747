import { baseApi } from 'store/api/baseApi';
import {
  IWorklogsIncludedTransformed,
  IWorklogsResponse,
  IWorklogsResponseTransformed,
} from 'modules/worklogs/types';
import { camelCase } from 'lodash';

export const worklogsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getWorklogs: builder.query<IWorklogsResponseTransformed, { startDate: string; endDate: string; }>({
      query: ({ startDate, endDate }) => ({
        url: '/calculated_worklogs',
        params: { 'filter[start_date]': startDate, 'filter[end_date]': endDate },
      }),
      providesTags: ['Worklogs'],
      transformResponse(response: IWorklogsResponse): IWorklogsResponseTransformed {
        const transformedIncluded = {
          coreMilestones: {} as IWorklogsIncludedTransformed['coreMilestones'],
          coreProjects: {} as IWorklogsIncludedTransformed['coreProjects'],
          coreUsers: {} as IWorklogsIncludedTransformed['coreUsers'],
          coreUserSlots: {} as IWorklogsIncludedTransformed['coreUserSlots'],
          jiraProjects: {} as IWorklogsIncludedTransformed['jiraProjects'],
        };

        response.included?.reduce((accumulator, record) => {
          const camelizedType = camelCase(record.type) as keyof IWorklogsIncludedTransformed;

          if (accumulator[camelizedType]) {
            accumulator[camelizedType][record.id] = record;
          }

          return accumulator;
        }, transformedIncluded);

        return { ...transformedIncluded, worklogs: response.data };
      },
    }),
    calculateWorklogs: builder.mutation<undefined, { startDate: string; endDate: string; }>({
      query: ({ startDate, endDate }) => ({
        url: '/calculated_worklogs/calculate',
        method: 'POST',
        body: {
          filter: {
            start_date: startDate,
            end_date: endDate,
          },
        },
      }),
      invalidatesTags: ['Worklogs'],
    }),
  }),
});

export const { useCalculateWorklogsMutation, useLazyGetWorklogsQuery } = worklogsApi;
