import { useSyncJiraIssuesMutation, useSyncJiraProjectsMutation } from 'modules/jira-projects/redux/queries';
import { useSyncJiraUsersMutation } from 'modules/jira-users/redux/queries';
import showNotification from 'utils/showNotification';

interface IUseJiraSyncReturn {
  syncJira: () => Promise<void>;
  isSyncing: boolean;
}

export const useJiraSync = (startDateParameter?: string, endDateParameter?: string): IUseJiraSyncReturn => {
  const [syncJiraProjects, { isLoading: isProjectsSyncing }] = useSyncJiraProjectsMutation();
  const [syncJiraUsers, { isLoading: isUserSyncing }] = useSyncJiraUsersMutation();
  const [syncJiraIssues, { isLoading: isIssuesSyncing }] = useSyncJiraIssuesMutation();

  const syncJira = (): Promise<void> => Promise.all([
    syncJiraProjects(undefined).unwrap(),
    syncJiraUsers(undefined).unwrap(),
  ])
    .then(() => syncJiraIssues({ startDate: startDateParameter, endDate: endDateParameter }).unwrap())
    .then(() => showNotification('success', { content: 'Jira synchronization succeed' }))
    .catch(() => showNotification('error', { content: 'Jira synchronization failed' }));

  return {
    syncJira,
    isSyncing: isUserSyncing || isProjectsSyncing || isIssuesSyncing,
  };
};
