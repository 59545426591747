const START_YEAR = 2016;

export const getYears = (): number[] => {
  const currentYear = new Date().getFullYear();
  const yearsArray: number[] = [];

  for (let year = START_YEAR; year <= currentYear; year++) {
    yearsArray.push(year);
  }

  return yearsArray;
};
