import { baseApi } from 'store/api/baseApi';
import { ICurrentUserResponse, ICurrentUserTransformed } from 'modules/current-user/types';

export const userApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query<ICurrentUserTransformed, undefined>({
      query: () => '/current_user',
      transformResponse: (response: ICurrentUserResponse) => ({
        user: response.data,
        settings: response.included?.[0],
      }),
    }),
  }),
});

export const { useGetUserQuery } = userApi;
