import React from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import { ISelectOption } from 'typings/shared';

export interface ICoreProjectsSelectProps {
  onSelectChange: (values: SingleValue<{ label: string; value: string; }>) => void;
  options: MultiValue<ISelectOption>;
  onFocus: () => void;
}

export function CoreProjectsSelect({ onSelectChange, options, onFocus }: ICoreProjectsSelectProps): JSX.Element {
  return (
    <Select
      closeMenuOnScroll
      isSearchable
      options={options}
      onFocus={onFocus}
      onChange={onSelectChange}
    />
  );
}
