import { IWorklogsResponseTransformed } from 'modules/worklogs/types';

export const getJiraProjectNames = (
  jiraProjectIds: string[], jiraProjects: IWorklogsResponseTransformed['jiraProjects'],
): string => (
  jiraProjectIds
    .map(jiraProjectId => {
      const { attributes: { name: jiraProjectName = '' } = {} } = jiraProjects[jiraProjectId] || {};

      return jiraProjectName;
    })
    .join(', ')
);
