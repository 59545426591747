import React from 'react';
import { Paths } from 'utils/const';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export function AddInstanceButton(): JSX.Element {
  return (
    <Link to={Paths.INSTANCE_NEW}>
      <Button variant="primary" className="mt-2">
        Add server
      </Button>
    </Link>
  );
}
