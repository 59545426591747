import { useMemo } from 'react';
import { format } from 'date-fns';
import { IWorklogsResponseTransformed } from 'modules/worklogs/types';
import { TDashboardFormatted, TDashboardItemsData } from 'modules/dashboard/types';

export const useFormatDashboard = (worklogsResponse: IWorklogsResponseTransformed | undefined): TDashboardFormatted => {
  const { worklogs = [], coreMilestones = {} } = worklogsResponse || {};

  return useMemo(() => {
    const dashboardItems = {} as TDashboardItemsData;

    worklogs.forEach(({ attributes, relationships }) => {
      const {
        coreMilestone: { data: { id: milestoneId } },
        coreProject: { data: { id: projectId } },
        coreUser: { data: { id: userId } },
        coreUserSlot: { data: { id: slotId } },
        jiraProjects,
      } = relationships;

      const relatedJiraProjectIds: string[] = [];

      jiraProjects?.data.forEach(({ id }) => {
        if (!relatedJiraProjectIds.includes(id)) {
          relatedJiraProjectIds.push(id);
        }
      });

      const date = format(new Date(coreMilestones[milestoneId].attributes.beginDate), 'LLLL yyyy');

      if (!dashboardItems[date]) {
        dashboardItems[date] = {};
      }

      const userToSlotMap = {
        [userId]: {
          slotId,
          worklog: attributes.jiraTracked,
          jiraProjectIds: relatedJiraProjectIds,
        },
      };

      const exists = dashboardItems[date][projectId];

      if (exists) {
        dashboardItems[date][projectId].userToSlotMap = {
          ...exists.userToSlotMap,
          ...userToSlotMap,
        };
      } else {
        dashboardItems[date][projectId] = { userToSlotMap };
      }
    });

    return Object.entries(dashboardItems)
      .map(([date, dashboardProject]) => ([date, Object.entries(dashboardProject)]));
  }, [worklogsResponse]);
};
