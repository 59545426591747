import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import NavigationBar from 'modules/navigation/components/NavigationBar/NavigationBar';
import Routes from 'modules/app/Routes';
import { useGetUserQuery } from 'modules/current-user/redux/queries';
import { useSyncCoreProjectsMutation } from 'modules/core-projects/redux/queries';
import { useSyncCoreMilestonesMutation } from 'modules/core-milestones/redux/queries';
import { useSyncCoreUsersMutation } from 'modules/core-users/redux/queries';
import showNotification from 'utils/showNotification';
import AppSpinner from 'modules/app/components/AppSpinner/AppSpinner';

function App(): JSX.Element | null {
  const { isSuccess } = useGetUserQuery(undefined);
  const [syncMilestones, { isSuccess: milestonesSynced }] = useSyncCoreMilestonesMutation(undefined);
  const [syncProjects, { isSuccess: projectsSynced }] = useSyncCoreProjectsMutation(undefined);
  const [syncUsers, { isSuccess: usersSynced }] = useSyncCoreUsersMutation(undefined);

  const isReady = isSuccess && milestonesSynced && projectsSynced && usersSynced;

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    syncUsers(undefined)
      .unwrap()
      .then(() => syncProjects(undefined))
      .then(() => syncMilestones(undefined))
      .then(() => showNotification('success', { content: 'Core synchronization succeed' }))
      .catch(() => showNotification('error', { content: 'Core synchronization failed' }));
  }, [isSuccess]);

  return isReady
    ? (
      <BrowserRouter>
        <NavigationBar />
        <Routes />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          closeOnClick
        />
      </BrowserRouter>
    )
    : <AppSpinner />;
}

export default App;
