import React, { useEffect } from 'react';
import { Container, Button, Alert } from 'react-bootstrap';
import { CoreProjectsSummary } from 'modules/dashboard/components/CoreProjectsSummary/CoreProjectsSummary';
import AppSpinner from 'modules/app/components/AppSpinner/AppSpinner';
import { MonthsNavigation } from 'shared/components/MonthsNavigation/MonthsNavigation';
import { useWorkLogsCalculation } from 'modules/dashboard/hooks/useWorklogsCalculation';
import { useMonthsNavigation } from 'shared/hooks/useMonthsNavigation';
import { useLazyGetWorklogsQuery } from 'modules/worklogs/redux/queries';
import { useCalculationDates } from 'modules/dashboard/hooks/useCalculationDates';
import { useHistory, Link } from 'react-router-dom';
import { useSyncCoreMilestonesMutation } from 'modules/core-milestones/redux/queries';
import { Paths } from 'utils/const';
import { calculateTitle } from 'modules/dashboard/const';
import { userApi } from 'modules/current-user/redux/queries';

function DashboardPage(): JSX.Element {
  const history = useHistory();
  const { yearValue, monthValue, handleSelectMonth, currentDate } = useMonthsNavigation();
  const { calculateWorkLogs, inProccess } = useWorkLogsCalculation();
  const { start, end } = useCalculationDates(yearValue, monthValue + 1);
  const [syncMilestones] = useSyncCoreMilestonesMutation(undefined);
  const [getWorklogs, { data: worklogs }] = useLazyGetWorklogsQuery(undefined);
  const { showTips, firstSync } = userApi.endpoints.getUser.useQueryState(undefined, {
    selectFromResult: state => state.data?.settings?.attributes,
  }) || {};

  useEffect(() => {
    history.push(`/?filter[start_date]=${start}&filter[end_date]=${end}`);
    syncMilestones({ startDate: start, endDate: end })
      .unwrap()
      .then(() => getWorklogs({ startDate: start, endDate: end }));
  }, [start, end]);

  return (
    <Container className="pb-3">
      {inProccess && <AppSpinner />}
      {showTips && (
        <Alert variant="primary">
          First of all you should add your Jira servers <Link to={Paths.INSTANCES}>here</Link>
        </Alert>
      )}
      {firstSync && showTips && (
        <Alert variant="success">
          Congratulations, all data has been successfully collected! Now you can attach core projects to jira projects
          <Link className="ml-1" to={Paths.CORE_PROJECTS}>here</Link>
        </Alert>
      )}
      <div className="mb-3 d-flex justify-content-between align-content-center">
        <h1 className="m-0">Dashboard</h1>
        <Button
          variant="primary"
          disabled={inProccess}
          onClick={calculateWorkLogs}
          className="mb-2"
          title={calculateTitle}
        >
          Calculate worklogs
        </Button>
      </div>
      <MonthsNavigation
        yearValue={yearValue}
        monthValue={monthValue}
        handleSelectMonth={handleSelectMonth}
        currentDate={currentDate}
      />
      {worklogs && <CoreProjectsSummary worklogs={worklogs} />}
    </Container>
  );
}

export default DashboardPage;
