import React, { forwardRef } from 'react';
import { Form } from 'react-bootstrap';

interface IRefProps {
  value: Date;
  onClick?: () => void;
  onChange?: () => void;
  onFocus?: () => void;
}

export const CustomDatepickerInput = forwardRef<HTMLInputElement | null, IRefProps>(
  ({ value, onClick, onChange, onFocus }, ref) => {
    const inputValue = String(value);

    return (
      <Form.Control
        ref={ref}
        type="text"
        value={inputValue}
        onClick={onClick}
        onChange={onChange}
        onFocus={onFocus}
      />
    );
  },
);
