export const API_URL = process.env.REACT_APP_API_URL as string;
export const SSO_LOGOUT_CALLBACK_URL = process.env.REACT_APP_SSO_LOGOUT_CALLBACK_URL as string;
export const SSO_LOGIN_CALLBACK_URL = process.env.REACT_APP_SSO_LOGIN_CALLBACK_URL as string;

export enum HTTP_STATUS_CODE {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  AUTHENTICATION_TIMEOUT = 419,
  INTERNAL_SERVER_ERROR = 500,
}

export enum Paths {
  ROOT = '/',
  INSTANCES = '/instances',
  INSTANCE_NEW = '/instances/new',
  INSTANCE = '/instances/:id',
  CORE_PROJECTS = '/core_projects',
  REPORTS = '/reports',
  NOT_FOUND = '*'
}

export const DATEPICKER_FORMAT = 'dd.MM.YYY';
