import React, { useMemo } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { Form as FormWrapper, Field } from 'react-final-form';
import InputField from 'shared/components/InputField/InputField';
import { useServerInfoSubmit } from 'modules/instances/hooks/useServerInfoSubmit';
import { Link, useParams } from 'react-router-dom';
import { useGetInstancesQuery } from 'modules/instances/redux/queries';
import { Paths } from 'utils/const';

function InstanceForm(): JSX.Element {
  const { id: idFromParameters } = useParams<{ id: string }>();

  const { instance } = useGetInstancesQuery(undefined, {
    selectFromResult: state => ({
      ...state,
      instance: state.data?.find(({ id }) => id === idFromParameters),
    }),
  });
  const { attributes: { name = '', url = '', jiraToken = '' } = {} } = instance || {};

  const initialValues = useMemo(() => ({ name, url, jiraToken }), [name, url, jiraToken]);
  const [onSubmit, errors] = useServerInfoSubmit(instance);

  return (
    <FormWrapper onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Card>
            <Card.Body>
              <Field name="name">
                {({ input }) => (
                  <InputField
                    value={input.value.valueOf()}
                    onChange={input.onChange}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    name={input.name}
                    label="Name"
                    error={errors.nameError}
                    className="mt-3"
                  />
                )}
              </Field>
              <Field name="url">
                {({ input }) => (
                  <InputField
                    value={input.value.valueOf()}
                    onChange={input.onChange}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    name={input.name}
                    label="Url"
                    error={errors.urlError}
                    className="mt-3"
                  />
                )}
              </Field>
              <Field name="jiraToken">
                {({ input }) => (
                  <InputField
                    value={input.value.valueOf()}
                    onChange={input.onChange}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    name={input.name}
                    label="Jira access key"
                    error={errors.jiraTokenError}
                    className="mt-3"
                  />
                )}
              </Field>
              <a
                href="https://id.atlassian.com/manage-profile/security/api-tokens"
                target="_blank"
                rel="noreferrer noopener"
                className="d-block text-primary"
              >
                Access key can be received here
              </a>
              <Button className="mt-3" variant="primary" type="submit">Save</Button>
              <Link to={Paths.INSTANCES}>
                <Button className="mt-3 ml-2" variant="dark" type="button">Cancel</Button>
              </Link>
            </Card.Body>
          </Card>
        </Form>
      )}
    </FormWrapper>
  );
}

export default InstanceForm;
