import { baseApi } from 'store/api/baseApi';

export const jiraUsersApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    syncJiraUsers: builder.mutation<undefined, undefined>({
      query: () => ({
        url: '/jira/users/sync',
        method: 'POST',
      }),
    }),
  }),
});

export const { useSyncJiraUsersMutation } = jiraUsersApi;
