import { baseApi } from 'store/api/baseApi';
import { IInstanceRecord } from 'modules/instances/types';

export const instancesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getInstances: builder.query<IInstanceRecord[], undefined>({
      query: () => '/jira/instances',
      transformResponse: (response: { data: IInstanceRecord[] }) => response.data,
      providesTags: ['Instances'],
    }),
    postInstance: builder.mutation<string, { jiraToken: string; name: string; url: string; }>({
      query: ({ jiraToken, name, url }) => ({
        url: '/jira/instances',
        method: 'POST',
        headers: { 'Content-Type': 'application/vnd.api+json' },
        body: JSON.stringify({
          data: {
            id: 'string',
            type: 'instances',
            attributes: {
              jira_token: jiraToken,
              name,
              url,
            },
          },
        }),
      }),
      invalidatesTags: ['Instances'],
    }),
    updateInstance: builder.mutation<string, IInstanceRecord>({
      query: instance => ({
        url: `/jira/instances/${instance.id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/vnd.api+json' },
        body: JSON.stringify({ data: instance }),
      }),
      invalidatesTags: ['Instances'],
    }),
    deleteInstance: builder.mutation<undefined, string>({
      query: id => ({
        url: `/jira/instances/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Instances'],
    }),
  }),
});

export const {
  useGetInstancesQuery,
  usePostInstanceMutation,
  useUpdateInstanceMutation,
  useDeleteInstanceMutation,
} = instancesApi;
