import React from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';
import { Paths } from 'utils/const';
import DashboardPage from 'pages/dashboard/DashboardPage';
import InstancesPage from 'pages/instances/InstancesPage';
import InstancePage from 'pages/instances/InstancePage';
import CoreProjectsPage from 'pages/coreProjects/CoreProjectsPage';
import ReportsPage from 'pages/reports/ReportsPage';
import NotFoundPage from 'pages/notFound/NotFoundPage';

const routes: RouteProps[] = [
  {
    path: Paths.ROOT,
    component: DashboardPage,
  },
  {
    path: Paths.INSTANCES,
    component: InstancesPage,
  },
  {
    path: Paths.INSTANCE_NEW,
    component: InstancePage,
  },
  {
    path: Paths.INSTANCE,
    component: InstancePage,
  },
  {
    path: Paths.CORE_PROJECTS,
    component: CoreProjectsPage,
  },
  {
    path: Paths.REPORTS,
    component: ReportsPage,
  },
  {
    path: Paths.NOT_FOUND,
    component: NotFoundPage,
  },
];

function Routes(): JSX.Element {
  return (
    <Switch>
      {routes.map(route => <Route key={route.path?.toString()} path={route.path} component={route.component} exact />)}
    </Switch>
  );
}

export default Routes;
