import { baseApi } from 'store/api/baseApi';
import {
  ICoreProjectsResponse,
  ICoreProjectsResponseTransformed,
} from 'modules/core-projects/types';
import { IJiraProjectsRecord } from 'modules/jira-projects/types';
import { GMap } from 'typings/base';

export const coreProjectsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getCoreProjects: builder.query<ICoreProjectsResponseTransformed, undefined>({
      query: () => '/core/projects',
      transformResponse: (response: ICoreProjectsResponse): ICoreProjectsResponseTransformed => ({
        data: response.data,
        jiraProjects: (response.included || []).reduce((result, jiraProject: IJiraProjectsRecord) => {
          if (jiraProject.type === 'jira_projects') {
            result[jiraProject.id] = jiraProject;
          }

          return result;
        }, {} as GMap<IJiraProjectsRecord>),
      }),
      providesTags: ['CoreProjects'],
    }),
    syncCoreProjects: builder.mutation<undefined, undefined>({
      query: () => ({
        url: '/core/projects/sync',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetCoreProjectsQuery,
  useSyncCoreProjectsMutation,
} = coreProjectsApi;
