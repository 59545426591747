import { ICalculateTracksTimeReturn, IUserToSlotRelations } from 'modules/dashboard/types';
import { GMap } from 'typings/base';
import { ICoreUserSlotsRecord } from 'modules/core-user-slots/types';

export const calculateTracksTime = (
  usersData: [string, IUserToSlotRelations][], coreUserSlots: GMap<ICoreUserSlotsRecord>,
): ICalculateTracksTimeReturn => {
  const result = { worklogsSum: 0, planedHoursSum: 0 };

  return usersData
    ? (
      usersData?.reduce((accumulator, [_, { slotId, worklog }]) => {
        const slot = coreUserSlots[slotId];
        const { attributes: { hours = '' } = {} } = slot || {};

        accumulator.worklogsSum += worklog;
        accumulator.planedHoursSum += Number(hours);

        return accumulator;
      }, result)
    )
    : result;
};
