import { baseApi } from 'store/api/baseApi';
import { handleRequestBody } from 'shared/helpers/handle-request-body';
import { IUserSettingsRecord } from 'modules/user-settings/types';
import { userApi } from 'modules/current-user/redux/queries';

interface IPatchUserSettingsParams {
  userId?: string;
  settings: IUserSettingsRecord;
}

export const userSettingApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    patchUserSettings: builder.mutation<IUserSettingsRecord, IPatchUserSettingsParams>({
      query: ({ settings, userId }) => ({
        url: `/users/${userId}/settings`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/vnd.api+json' },
        body: handleRequestBody({ data: settings }),
      }),
      transformResponse: ({ data } = {}) => data,
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        dispatch(
          userApi.util.updateQueryData('getUser', undefined, draft => {
            draft.settings = data;
          }),
        );
      },
    }),
  }),
});

export const { usePatchUserSettingsMutation } = userSettingApi;
