import { useGetUserQuery } from 'modules/current-user/redux/queries';
import { USER_ROLES } from 'modules/current-user/const';

const PERMITTED_ROLES = [USER_ROLES.superadmin, USER_ROLES.account, USER_ROLES.leadAccount];

export function useSendToCorePermissions(): boolean {
  const { data: { user: currentUser } = {} } = useGetUserQuery(undefined);

  return PERMITTED_ROLES.includes(currentUser?.attributes.role || '');
}
