import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { TUserToSlotMap } from 'modules/dashboard/types';
import { ICoreProjectRecord } from 'modules/core-projects/types';
import { calculateTracksTime } from 'modules/dashboard/helpers/calculateTracksTime';
import { IWorklogsResponseTransformed } from 'modules/worklogs/types';
import { CoreProjectRow } from 'modules/dashboard/components/CoreProjectRow/CoreProjectRow';
import { getJiraProjectNames } from 'modules/dashboard/helpers/getJiraProjectNames';
import { useSendToCore } from 'modules/dashboard/hooks/useSendToCore';
import { useSendToCorePermissions } from 'modules/dashboard/hooks/useSendToCorePermissions';
import { sendToCoreTitle } from 'modules/dashboard/const';

export interface ICoreProjectProps {
  project: ICoreProjectRecord;
  userToSlotMap: TUserToSlotMap;
  worklogsData?: IWorklogsResponseTransformed;
}

export const CoreProject = React.memo(
  ({ project, userToSlotMap, worklogsData }: ICoreProjectProps): JSX.Element => {
    const { coreUsers = {}, coreUserSlots = {}, jiraProjects = {} } = worklogsData || {};
    const { id, attributes: { name, projectLink } } = project || {};
    const usersData = useMemo(() => Object.entries(userToSlotMap), [userToSlotMap]);
    const [{ planedHoursSum, worklogsSum }, setTiming] = useState({ worklogsSum: 0, planedHoursSum: 0 });
    const sendToCore = useSendToCore({ projectIds: [id] });
    const sendToCoreAllowed = useSendToCorePermissions();
    const projectName = <b>{name}</b>;

    useEffect(() => setTiming(calculateTracksTime(usersData, coreUserSlots)), [usersData]);

    return (
      <Card key={id}>
        <Card.Header>
          {projectLink ? <a href={projectLink} target="__blank">{projectName}</a> : projectName}
          <span className="ml-3">total planned: {planedHoursSum} h</span>
          <span className="ml-3">total tracked: {Number(worklogsSum).toFixed(2)} h</span>
          {sendToCoreAllowed && (
            <Button className="ml-auto d-block" onClick={() => sendToCore()} variant="dark" title={sendToCoreTitle}>
              Send to core
            </Button>
          )}
        </Card.Header>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Hours planned</th>
                <th>Hours tracked</th>
                <th>Jira Hours Tracked</th>
                <th>Jira projects</th>
              </tr>
            </thead>
            <tbody>
              {usersData.map(([userId, { slotId, worklog, jiraProjectIds = [] }]) => (
                <CoreProjectRow
                  key={userId}
                  user={coreUsers[userId]}
                  slot={coreUserSlots[slotId]}
                  worklog={worklog}
                  jiraProjectNames={getJiraProjectNames(jiraProjectIds, jiraProjects)}
                  sendToCore={sendToCoreAllowed ? sendToCore : undefined}
                />
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  },
);
