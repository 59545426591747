import React from 'react';
import { Alert, Container } from 'react-bootstrap';
import CoreProjectsTable from 'modules/core-projects/components/core-projects-table/CoreProjectsTable';
import { useGetCoreProjectsQuery } from 'modules/core-projects/redux/queries';
import { useGetInstancesQuery } from 'modules/instances/redux/queries';
import { userApi } from 'modules/current-user/redux/queries';

function CoreProjectsPage(): JSX.Element {
  const { data: { data: projects } = {} } = useGetCoreProjectsQuery(undefined);
  const showTips = userApi.endpoints.getUser.useQueryState(undefined, {
    selectFromResult: state => state.data?.settings?.attributes?.showTips,
  });

  useGetInstancesQuery(undefined);

  return (
    <Container>
      {showTips && <Alert variant="primary">Attach core projects to Jira projects and go to the Dashboard</Alert>}
      <h1>ERP Core projects</h1>
      {projects && <CoreProjectsTable projects={projects} />}
    </Container>
  );
}

export default CoreProjectsPage;
