import { GMap } from 'typings/base';
import showNotification from 'utils/showNotification';

interface IApiFailedResponse {
  data: {
    errors: {
      detail: GMap<string>;
    }[];
  };
}

export const getApiErrors = (response: IApiFailedResponse): GMap<string> => {
  try {
    return response.data.errors[0].detail;
  } catch {
    showNotification('error', { title: 'Failed to parse api error', content: '' });

    return {};
  }
};
