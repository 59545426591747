import { Button } from 'react-bootstrap';
import React, { useCallback, useState } from 'react';
import { ICoreProjectRecord } from 'modules/core-projects/types';
import { useGetCoreProjectsQuery } from 'modules/core-projects/redux/queries';
import AttachJiraProjectsModal
  from 'modules/jira-projects/components/attach-jira-projects-modal/AttachJiraProjectsModal';

export interface ICoreProjectRowProps {
  project: ICoreProjectRecord;
}

export function CoreProjectRow(
  { project: { id, attributes: { name }, relationships: { jiraProjects: { data } } } }: ICoreProjectRowProps,
): JSX.Element {
  const { data: { jiraProjects = {} } = {} } = useGetCoreProjectsQuery(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = useCallback(() => setIsOpen(state => !state), []);
  const relatedJiraProjects = data?.map(({ id: jiraId }) => jiraProjects[jiraId]);

  return (
    <>
      <tr key={id}>
        <td className="align-middle">{name}</td>
        <td className="align-middle w-50">
          {relatedJiraProjects.length > 0
            ? relatedJiraProjects.map(({ id: jiraId }) => jiraProjects[jiraId].attributes.name).join(', ')
            : ''}
        </td>
        <td className="text-right">
          <Button onClick={toggleModal} variant="dark">Attach jira projects</Button>
        </td>
      </tr>
      {isOpen && (
        <AttachJiraProjectsModal
          onCloseClick={toggleModal}
          coreProjectId={id}
          relatedJiraProjects={relatedJiraProjects}
        />
      )}
    </>
  );
}
