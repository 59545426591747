import {
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';
import { HTTP_STATUS_CODE, SSO_LOGIN_CALLBACK_URL } from 'utils/const';

export const unauthorizedMiddleware: Middleware = () => next => action => {
  if (isRejectedWithValue(action) && action.payload.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
    window.location.assign(SSO_LOGIN_CALLBACK_URL);
  }

  return next(action);
};
