import { Button, Modal } from 'react-bootstrap';
import React from 'react';

export function JiraSyncModal({ hideModal }: { hideModal: () => void }): JSX.Element {
  return (
    <Modal show centered onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Great job!</Modal.Title>
      </Modal.Header>
      <Modal.Body>The first sync will be done tonight, so come back tomorrow during business hours</Modal.Body>
      <Modal.Footer><Button variant="primary" onClick={hideModal}>Ok</Button></Modal.Footer>
    </Modal>
  );
}
