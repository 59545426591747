import React, { useState, useCallback, useMemo } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { CoreProjectsSelect } from 'modules/core-projects/components/core-projects-select/CoreProjectsSelect';
import { useGetCoreProjectsQuery } from 'modules/core-projects/redux/queries';
import { formatCoreProjectOptions } from 'modules/core-projects/helpers/format-core-project-options';
import { ISelectOption } from 'typings/shared';
import DatePicker from 'react-datepicker';
import { DATEPICKER_FORMAT } from 'utils/const';
import { CustomDatepickerInput } from 'shared/components/CustomDatepickerInput/CustomDatepickerInput';
import 'react-datepicker/dist/react-datepicker.css';
import { useLazyGetReportQuery } from 'modules/reports/redux/queries';
import AppSpinner from 'modules/app/components/AppSpinner/AppSpinner';

function ReportsPage(): JSX.Element {
  const { coreProjectOptions } = useGetCoreProjectsQuery(undefined, {
    selectFromResult: state => ({
      coreProjectOptions: formatCoreProjectOptions(state.data?.data || []),
    }),
  });
  const [getReport, { isFetching }] = useLazyGetReportQuery();
  const [selectedProject, setSelectedProject] = useState<ISelectOption | null>(null);
  const now = useMemo(() => new Date(), []);
  const [startDate, setStartDate] = useState<Date>(now);
  const [endDate, setEndDate] = useState<Date>(now);
  const [errors, setErrors] = useState({ coreProject: '', date: '' });

  const changeStartDate = useCallback(([start, end]: [Date, Date]) => {
    setStartDate(start);
    setEndDate(end);
  }, []);

  const clearError = (field: string): void => setErrors(stateErrors => ({ ...stateErrors, [field]: '' }));

  const onDownloadClick = (): void => {
    if (!selectedProject || !endDate || !startDate) {
      setErrors({
        coreProject: !selectedProject ? 'Select project' : '',
        date: !endDate ? 'Select end date' : '',
      });

      return;
    }

    getReport({ startDate, endDate, coreProjectId: selectedProject?.value || '' }).unwrap().then(url => {
      if (url) {
        window.location.assign(url);
      }
    });
  };

  return (
    <Container>
      {isFetching && <AppSpinner />}
      <h1>Report generator</h1>
      <Row>
        <Col xs={6}>
          Select Project
          <CoreProjectsSelect
            options={coreProjectOptions}
            onSelectChange={project => setSelectedProject(project)}
            onFocus={() => clearError('coreProject')}
          />
          {errors.coreProject && <span className="text-danger ml-2">{errors.coreProject}</span>}
        </Col>
        <Col xs={3}>
          Select Dates Range
          <DatePicker
            dateFormat={DATEPICKER_FORMAT}
            selected={startDate}
            onChange={changeStartDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            selectsRange
            onFocus={() => clearError('date')}
            customInput={<CustomDatepickerInput value={startDate} />}
          />
          {errors.date && <span className="text-danger ml-2">{errors.date}</span>}
        </Col>
        <Col className="d-flex align-items-start mt-4" xs={3}>
          <Button onClick={onDownloadClick} variant="primary">Generate</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default ReportsPage;
