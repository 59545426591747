import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'utils/const';
import queryString from 'query-string';

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    credentials: 'include',
    paramsSerializer: (parameters = {}) => queryString.stringify(parameters, { skipEmptyString: true }),
  }),
  tagTypes: ['Organizations', 'Instances', 'CoreProjects', 'Worklogs'],
  endpoints: () => ({}),
});
