import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { baseApi } from 'store/api/baseApi';
import { unauthorizedMiddleware } from 'middlewares/unauthorized-middleware';

export const store = configureStore({
  reducer: combineReducers({
    [baseApi.reducerPath]: baseApi.reducer,
  }),
  middleware: getDefaultMiddleware => (
    getDefaultMiddleware()
      .concat(baseApi.middleware)
      .concat(unauthorizedMiddleware)
  ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
