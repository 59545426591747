import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './NotFoundPage.module.scss';

function NotFoundPage(): JSX.Element {
  return (
    <Container className={styles.container}>
      <div className="jumbotron text-center mt-4 mx-auto col-sm-10 col-lg-8">
        <h1>404</h1>
        <p className="lead">Page not found</p>
        <p>
          <a className="btn btn-info" href="/">Go to main page</a>
        </p>
      </div>
    </Container>
  );
}

export default NotFoundPage;
