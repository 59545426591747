import { baseApi } from 'store/api/baseApi';
import { IJiraProjectsRecord } from 'modules/jira-projects/types';

interface IJiraIssuesParameters {
  startDate?: string;
  endDate?: string;
}

export const jiraProjectsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getJiraProjects: builder.query<IJiraProjectsRecord[], string | undefined>({
      query: instanceId => ({
        url: '/jira/projects',
        params: { 'filter[instance_id]': instanceId },
      }),
      transformResponse: (response: { data: IJiraProjectsRecord[] }): IJiraProjectsRecord[] => response.data,
    }),
    syncJiraProjects: builder.mutation<undefined, undefined>({
      query: () => ({
        url: '/jira/projects/sync',
        method: 'POST',
      }),
    }),
    syncJiraIssues: builder.mutation<undefined, IJiraIssuesParameters>({
      query: ({ startDate, endDate }) => {
        const body: Record<string, string> = {};

        if (startDate) {
          body['start_date'] = startDate;
        }

        if (endDate) {
          body['end_date'] = endDate;
        }

        return {
          url: '/jira/issues/sync',
          method: 'POST',
          body,
        };
      },
    }),
    syncUpdatedJiraWorklogs: builder.mutation<undefined, IJiraIssuesParameters>({
      query: ({ startDate, endDate }) => {
        const body: Record<string, string> = {};

        if (startDate) {
          body['start_date'] = startDate;
        }

        if (endDate) {
          body['end_date'] = endDate;
        }

        return {
          url: '/jira/worklogs/sync',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});
// eslint-disable-next-line max-len
export const { useGetJiraProjectsQuery, useSyncJiraProjectsMutation, useSyncJiraIssuesMutation, useSyncUpdatedJiraWorklogsMutation } = jiraProjectsApi;
