import { baseApi } from 'store/api/baseApi';
import { IMilestoneRecord } from 'modules/core-milestones/types';

export const coreMilestonesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    syncCoreMilestones: builder.mutation<IMilestoneRecord[], { startDate?: string; endDate?: string; } | undefined>({
      query: ({ startDate, endDate } = {}) => {
        const body: Record<string, Record<string, string>> = { filter: {} };

        if (startDate) {
          body.filter['start_date'] = startDate;
        }

        if (endDate) {
          body.filter['end_date'] = endDate;
        }

        return {
          url: '/core/milestones/sync',
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: { data: IMilestoneRecord[] }) => response.data,
    }),
  }),
});

export const { useSyncCoreMilestonesMutation } = coreMilestonesApi;
