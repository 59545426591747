import React from 'react';
import cn from 'classnames';
import { Form } from 'react-bootstrap';
import './input-field.scss';

// TODO импортировать тип из evrone-erp-ui
type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

interface IInputFieldProps {
  className?: string;
  error?: string;
  label: string;
  name: string;
  onBlur: () => void;
  onChange: (event: React.FormEvent<FormControlElement>) => void;
  onFocus: () => void;
  type?: 'text' | 'url' | 'email' | 'number' | 'password';
  value: string;
  required?: boolean;
}

function InputField({
  className,
  error,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  type = 'text',
  value,
  required = false,
}: IInputFieldProps): JSX.Element {
  return (
    <Form.Group className={cn('input-field', className)}>
      <Form.Control
        className={cn('input-field__control', { 'border-danger': !!error })}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={label}
        required={required}
      />
      <Form.Label className="input-field__label" htmlFor={name}>{label}</Form.Label>
      {error && <span className="text-danger ml-2">{error}</span>}
    </Form.Group>
  );
}

export default InputField;
