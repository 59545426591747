import React from 'react';
import { ICoreUserRecord } from 'modules/core-users/types';
import { ICoreUserSlotsRecord } from 'modules/core-user-slots/types';
import { Button } from 'react-bootstrap';
import { sendToCoreTitle } from 'modules/dashboard/const';

export interface ICoreProjectRowProps {
  slot: ICoreUserSlotsRecord;
  worklog: number;
  jiraProjectNames?: string;
  user: ICoreUserRecord;
  sendToCore?: (userId?: string) => void;
}

export function CoreProjectRow(
  { slot, worklog, jiraProjectNames, user, sendToCore }: ICoreProjectRowProps,
): JSX.Element {
  const { attributes: { hours = 0, trackedHours = 0 } = {} } = slot || {};
  const { name: userName, userLink } = user.attributes;

  return (
    <tr>
      <td>{userLink ? <a className="text-primary" target="__blank" href={userLink}>{userName}</a> : userName}</td>
      <td>
        {Math.trunc(Number(hours))} h
        {!slot && '(there is no slot for the user)'}
      </td>
      <td>{trackedHours || 0} h</td>
      <td className="d-flex justify-between">
        {worklog} h
        {sendToCore && (
          <Button
            className="ml-auto btn-sm"
            onClick={() => sendToCore(user.id)}
            variant="outline-dark"
            title={sendToCoreTitle}
          >
            Send to core
          </Button>
        )}
      </td>
      <td>{jiraProjectNames && (jiraProjectNames)}</td>
    </tr>
  );
}
