import { baseApi } from 'store/api/baseApi';
import { format } from 'date-fns';
import { DATEPICKER_FORMAT } from 'utils/const';

interface IReportsParameters {
  startDate: Date;
  endDate: Date;
  coreProjectId: string;
}

export const reportsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getReport: builder.query<string | undefined, IReportsParameters>({
      query: ({ startDate, endDate, coreProjectId }) => ({
        url: '/reports/generate',
        params: {
          start_date: format(startDate, DATEPICKER_FORMAT),
          end_date: format(endDate, DATEPICKER_FORMAT),
          core_project_id: coreProjectId,
        } }),
      transformResponse: ({ data }): string => data?.attributes?.url,
    }),
  }),
});

export const { useLazyGetReportQuery } = reportsApi;
