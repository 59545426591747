import React from 'react';
import { Table } from 'react-bootstrap';
import { ICoreProjectRecord } from 'modules/core-projects/types';
import { CoreProjectRow } from 'modules/core-projects/components/core-project-row/CoreProjectRow';

export interface ICoreProjectsTableProps {
  projects: ICoreProjectRecord[];
}

function CoreProjectsTable({ projects }: ICoreProjectsTableProps): JSX.Element | null {
  return (
    <Table hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Attached Jira projects</th>
          <th className="text-right">Action</th>
        </tr>
      </thead>
      <tbody>{projects.map(project => <CoreProjectRow key={project.id} project={project} />)}</tbody>
    </Table>
  );
}

export default CoreProjectsTable;
