import React from 'react';
import styles from './Notification.module.scss';

export interface INotificationProps {
  title?: string;
  content: string | string[];
}

function Notification({ content, title }: INotificationProps): JSX.Element {
  if (Array.isArray(content)) {
    return (
      <div className={styles.notificationWrap}>
        {title && <span className="d-block">{title}</span>}
        <ul className={styles.notificationList}>
          {content.map((message: string) => <li key={message}>{message}</li>)}
        </ul>
      </div>
    );
  }

  return (
    <div className={styles.notificationWrap}>
      {title && <span className="d-block">{title}</span>}
      <span>{content}</span>
    </div>
  );
}

export default Notification;
