import { useDateGetParameters } from './useDateGetParameters';

export const useCalculationDates = (yearValue: number, monthValue: number): { start: string; end: string; } => {
  const { startDateParameter, endDateParameter } = useDateGetParameters();

  const start = `1.${monthValue}.${yearValue}` || startDateParameter;
  const lastDay = new Date(yearValue, monthValue, 0).getDate();
  const end = `${lastDay}.${monthValue}.${yearValue}` || endDateParameter;

  return { start, end };
};
