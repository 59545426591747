import React, { useMemo } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useFormatDashboard } from 'modules/dashboard/hooks/useFormatDashboard';
import { CoreProject } from 'modules/dashboard/components/CoreProject/CoreProject';
import { IWorklogsResponseTransformed } from 'modules/worklogs/types';
import { useSendToCore } from 'modules/dashboard/hooks/useSendToCore';
import { useSendToCorePermissions } from 'modules/dashboard/hooks/useSendToCorePermissions';
import { sendToCoreTitle } from 'modules/dashboard/const';

export function CoreProjectsSummary({ worklogs }: { worklogs: IWorklogsResponseTransformed }): JSX.Element {
  const { coreProjects = {} } = worklogs || {};
  const dates = useFormatDashboard(worklogs);
  const projectIds = useMemo(() => Object.keys(coreProjects), []);
  const onSendTracks = useSendToCore({ projectIds });
  const sendToCoreAllowed = useSendToCorePermissions();

  return (
    <div className="mt-3">
      {dates.map(([date, projects]) => (
        <Card key={date}>
          <Card.Header><b>{date}</b></Card.Header>
          <Card.Body>
            {projects.map(([projectId, { userToSlotMap }]) => (
              <CoreProject
                key={projectId}
                project={coreProjects[projectId]}
                userToSlotMap={userToSlotMap}
                worklogsData={worklogs}
              />
            ))}
          </Card.Body>
        </Card>
      ))}
      {dates.length > 0 && sendToCoreAllowed && (
        <Button title={sendToCoreTitle} className="ml-auto mt-3 d-block" onClick={() => onSendTracks()}>
          Send all to core
        </Button>
      )}
    </div>
  );
}
